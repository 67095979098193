<template>
  <a-card>
    <a-tabs :default-active-key="tabsType"> 
      <a-tab-pane :key="1" tab="PDA采集器">
        <pda/>
      </a-tab-pane>
      <a-tab-pane :key="2" tab="隐形码采集器">
        <invisible></invisible>
      </a-tab-pane>
    </a-tabs>
  </a-card>

</template>
<script setup>
  import { ref } from "vue";
  import pda from './components/pda.vue';
  import invisible from './components/invisible'
  const tabsType = ref(1);
</script>